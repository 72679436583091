import { FC, useEffect, useRef, useState } from 'react'

import { captureException } from '@sentry/browser'
import ReactPlayer from 'react-player'

import IFramePlayer from 'src/components/IFramePlayer/IFramePlayer'
import Loading from 'src/components/Library/Loading'
import LoomPlayer from 'src/components/LoomPlayer/LoomPlayer'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'src/Providers'

import { TaskTrackingType } from '../../Courses/types'

interface VideoComponentProps {
  taskId?: number
  video?: string
  onError: (error: string) => void
  learnerTaskTracking?: TaskTrackingType
  onUpdateLearnerTaskTracking?: any
  setTaskStatus?: (taskStatus: any) => void
  parentClassName?: string
  progressData?: any[]
}

const VideoComponent: FC<VideoComponentProps> = ({
  taskId,
  video,
  onError,
  learnerTaskTracking,
  onUpdateLearnerTaskTracking,
  setTaskStatus,
  parentClassName,
}) => {
  const { currentUser } = useAuth()
  const player = useRef<ReactPlayer | null>(null)
  const [loading, setLoading] = useState(false)
  const [loomVideo, setLoomVideo] = useState('')
  const [videoUrl, setVideoUrl] = useState('')

  const [iFrameVideo, setIFrameVideo] = useState('')

  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (player && video) {
      setVideoUrl(video)
      setLoading(false)
    }
  }, [player, video])

  useEffect(() => {
    {
      let loomVideoUrl = video
      if (loomVideoUrl && loomVideoUrl.includes('loom.com')) {
        if (loomVideoUrl.includes('loom.com/embed/')) {
          loomVideoUrl = loomVideoUrl.replace(
            'loom.com/embed/',
            'loom.com/share/',
          )
        }
        if (loomVideoUrl.includes('?')) {
          loomVideoUrl = loomVideoUrl.split('?')[0]
        }
        setLoomVideo(loomVideoUrl)
      }
    }
  }, [video])

  useEffect(() => {
    {
      // Handle IFrame video URLs
      const iFrameVideoUrl = video
      if (
        iFrameVideoUrl &&
        (iFrameVideoUrl.includes('storyxpress.co') ||
          iFrameVideoUrl.includes('.mp4'))
      ) {
        setIFrameVideo(iFrameVideoUrl)
      }
    }
  }, [video])

  useEffect(() => {
    {
      // Handle Steam MS video URLs. Can use iFrame video player
      const iFrameVideoUrl = video
      if (
        iFrameVideoUrl &&
        iFrameVideoUrl.includes('stafflinkcomau') &&
        iFrameVideoUrl.includes('sharepoint')
      ) {
        setIFrameVideo(iFrameVideoUrl.match(/src="([^"]+)"/)?.[1])
      }
    }
  }, [video])

  const handleProgress = (currentTime, completed = 0) => {
    if (
      learnerTaskTracking?.id &&
      currentTime !== 0 &&
      currentTime !== learnerTaskTracking?.progressData?.[0] &&
      !currentUser.isClientAlias
    ) {
      onUpdateLearnerTaskTracking({
        variables: {
          id: learnerTaskTracking?.id,
          input: {
            progressData: JSON.stringify(currentTime),
            status:
              completed >= 0.95 || learnerTaskTracking.status === 'COMPLETED'
                ? 'COMPLETED'
                : 'IN_PROGRESS',
            lessonLength: currentTime / completed,
          },
        },
      })
      if (completed >= 0.95) {
        if (learnerTaskTracking.status === 'IN_PROGRESS') {
          setTaskStatus((vals) => {
            return [
              ...vals.filter((taskStatus) => taskStatus.taskId !== taskId),
              {
                taskId,
                status: 'COMPLETED',
              },
            ]
          })
        }
        trackEvent('Learner', 'Finish Video', {
          trackingStatus: learnerTaskTracking.status,
        })
      }
    }
  }

  const handleReactPlayerError = (error: { message: string; data: any }) => {
    captureException(
      new Error(
        `React Video player error: ${error.message}, data: ${error.data}`,
      ),
    )
    onError(error.message)
  }

  return (
    <div className={parentClassName}>
      {(() => {
        if (loading) {
          return (
            <div className="w-full h-full flex">
              <Loading />
            </div>
          )
        } else if (video) {
          if (loomVideo) {
            return (
              <LoomPlayer
                onTimeUpdate={(currentTime, endTime = null) => {
                  if (endTime)
                    handleProgress(currentTime, currentTime / endTime)
                  else handleProgress(currentTime)
                }}
                startTime={learnerTaskTracking?.progressData[0] as number}
                onError={onError}
                video={loomVideo}
              />
            )
          } else if (iFrameVideo) {
            return (
              <IFramePlayer
                video={iFrameVideo}
                onTimeUpdate={(currentTime, endTime = null) => {
                  if (endTime)
                    handleProgress(currentTime, currentTime / endTime)
                  else handleProgress(currentTime)
                }}
                startTime={learnerTaskTracking?.progressData[0]}
              />
            )
          } else {
            return (
              <div className="player-wrapper">
                <ReactPlayer
                  ref={player}
                  onError={(error) => handleReactPlayerError(error)}
                  data-testid="react-player-video"
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={videoUrl}
                  controls={true}
                  progressInterval={15000}
                  onProgress={(progress) => {
                    // if the video hasn't started playing, return - we already have onReady
                    if (progress.playedSeconds === 0) return
                    // if the video has ended, return - we already have on ended prop
                    // * if loaded seconds is a round number than the video has ended
                    if (progress.loadedSeconds % 1 === 0) return

                    handleProgress(progress.playedSeconds, progress.played)
                  }}
                  // handle progress when user pauses so we know where they are up to
                  onPause={() => {
                    const currentTime = player.current.getCurrentTime()
                    const videoLength = player.current.getDuration()
                    // if there is a problem getting video length or current time, don't do anything
                    if (!currentTime || !videoLength) return
                    const completed = currentTime / videoLength
                    // check that completed is a number between 0 and 1
                    if (completed < 0 || completed > 1) return
                    handleProgress(currentTime, completed)
                  }}
                  onEnded={() => {
                    handleProgress(player.current.getCurrentTime(), 1)
                  }}
                  // set the video to start at the time the user left off
                  onReady={() => {
                    if (!learnerTaskTracking?.status) return
                    if (learnerTaskTracking.status === 'COMPLETED') return
                    // there can be bugs with setting progress when the user has already
                    // completed the video, so just set progress the first time they watch the video ^

                    // There is only one element in the array, so we can just get the first element
                    const progressValue = learnerTaskTracking.progressData[0]
                    if (typeof progressValue !== 'string') return
                    if (progressValue === '') return
                    const progressFloat = parseFloat(progressValue)
                    if (typeof progressFloat !== 'number') return
                    player.current.seekTo(progressFloat)
                  }}
                  config={{
                    file: {
                      attributes: {
                        crossOrigin: 'true',
                      },
                    },
                  }}
                />
              </div>
            )
          }
        }
      })()}
    </div>
  )
}

export default VideoComponent
