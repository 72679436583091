import { FC } from 'react'

import {
  DocumentTextIcon,
  MapIcon,
  PuzzlePieceIcon,
  VideoCameraIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'
import Typography from '@mui/material/Typography'

import { LessonType } from 'src/types/enums'

interface Props {
  type: LessonType
  title: string
}

const PlayerHeader: FC<Props> = ({ title, type }) => {
  return (
    <div className="flex flex-row items-center pb-2">
      <div className="flex rounded-full w-12 h-12 bg-indigo-50 justify-center items-center	">
        {type === LessonType.video || type === LessonType.videoWithText ? (
          <VideoCameraIcon className="w-8 h-8 text-indigo-600 stroke-1" />
        ) : type === LessonType.processMap ? (
          <MapIcon className="w-8 h-8 text-indigo-600 stroke-1" />
        ) : type === LessonType.embed || type === LessonType.embedWithText ? (
          <CodeBracketIcon className="w-8 h-8 text-indigo-600 stroke-1" />
        ) : type === LessonType.text ? (
          <DocumentTextIcon className="w-8 h-8 text-indigo-600 stroke-1" />
        ) : (
          <PuzzlePieceIcon className="w-8 h-8 text-indigo-600 stroke-1" />
        )}
      </div>
      <Typography component="div" className="text-lg md:text-xl leading-7 m-4">
        {title}
      </Typography>
    </div>
  )
}

export default PlayerHeader
