import { FC, useState } from 'react'

import LearnerQuiz from 'src/components/Learner/Courses/Quiz/LearnerQuiz'
import { SetTaskStatus, TaskType } from 'src/components/Learner/Courses/types'

interface QuizPlayerProps {
  task: TaskType
  learnerTaskTracking: any
  updateLearnerTaskTracking: () => void
  setTaskStatus: SetTaskStatus
}

const QuizPlayer: FC<QuizPlayerProps> = ({
  task,
  learnerTaskTracking,
  updateLearnerTaskTracking,
  setTaskStatus,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0)

  //check if question.question is specified
  //check if there is at least 1 choice
  //check if there is at least 1 correct choice
  //remove any choice text that isn't filled out
  const questions = task?.quiz
    .map((question) => {
      question.choices = question.choices.filter((choice) => {
        {
          return choice['text'] !== '' && choice['text'] !== null
        }
      })

      if (
        question.question &&
        question.choices.length > 0 &&
        question.choices.find((choice) => choice['isAnswer'] === true)
      ) {
        return question
      }

      return null
    })
    .filter((question) => question)

  return (
    <>
      <LearnerQuiz
        questions={questions}
        currentQuestion={currentQuestion}
        onSetCurrentQuestion={setCurrentQuestion}
        learnerTaskTracking={learnerTaskTracking}
        onUpdateLearnerTaskTracking={updateLearnerTaskTracking}
        setTaskStatus={setTaskStatus}
        taskId={task.id}
        showAnswers={task.showQuizAnswers}
      />
    </>
  )
}

export default QuizPlayer
