export const GET_AUTHOR = gql`
  query GetAuthor($searchId: Int!) {
    user(id: $searchId) {
      id
      name
      avatarUrl
    }
  }
`

//mutation for updating task tracking
export const UPDATE_LEARNER_TASK_TRACKING_MUTATION = gql`
  mutation UpdateLearnerTaskTrackingMutation(
    $id: Int!
    $input: UpdateLearnerTaskTrackingInput!
  ) {
    updateLearnerTaskTracking(id: $id, input: $input) {
      id
    }
  }
`
