import { FC, useEffect, useState } from 'react'

import {
  UpdateLearnerTaskTrackingMutation,
  UpdateLearnerTaskTrackingMutationVariables,
  type LearnerTrackingStatus,
} from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import QuizPlayer from 'src/components/Learner/Courses/CourseCell/CoursePlayer/TaskCell/CoursePlayerView/QuizPlayer/QuizPlayer'
import { UPDATE_LEARNER_TASK_TRACKING_MUTATION } from 'src/components/Learner/Courses/queries'
import { SetTaskStatus, TaskType } from 'src/components/Learner/Courses/types'
import VideoComponent from 'src/components/Learner/Library/VideoComponent/VideoComponent'
import RichTextEditor from 'src/components/Library/RichTextEditor/RichTextEditor'
import ProcessMapViewerCell from 'src/components/ProcessMapViewerCell'
import { LessonType } from 'src/types/enums'
import { useAuth } from 'web/src/Providers'

import EmbedComponent from './EmbedComponent/EmbedComponent'
import PlayerHeader from './PlayerHeader/PlayerHeader'

interface Props {
  task: TaskType | null
  setTaskStatus: SetTaskStatus | undefined
  isPreview: boolean
}

const CoursePlayerView: FC<Props> = ({ task, setTaskStatus, isPreview }) => {
  const learnerTaskTracking = task.learnerTaskTrackings?.[0]
  const isMobile = useMediaQuery('(max-width: 769px)')
  const { currentUser } = useAuth()
  const [taskType, setTaskType] = useState<LessonType>()
  const [margin, setMargin] = useState<string>('')
  const [videoURL, setVideoURL] = useState('')
  const [embedURL, setEmbedURL] = useState('')
  const [updateLearnerTaskTracking] = useMutation<
    UpdateLearnerTaskTrackingMutation,
    UpdateLearnerTaskTrackingMutationVariables
  >(UPDATE_LEARNER_TASK_TRACKING_MUTATION, {
    onCompleted: () => {},
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const [textLayout, setTextLayout] = useState('fullWidth')

  useEffect(() => {
    if (task) {
      if (task.video && task.text) {
        setTaskType(LessonType.videoWithText)
        setMargin('mx-6')
        setVideoURL(task.video)
        setTextLayout(task.textLayout)
      } else if (task.video) {
        setTaskType(LessonType.video)
        setMargin('mx-6')
        setVideoURL(task.video)
      } else if (task.processMapId) {
        setTaskType(LessonType.processMap)
        setMargin('mx-6')
      } else if (task.embedURL && task.text) {
        setTaskType(LessonType.embedWithText)
        setMargin('max-w-[900px] mx-auto')
        setEmbedURL(task.embedURL)
        setTextLayout(task.textLayout)
      } else if (task.embedURL) {
        setTaskType(LessonType.embed)
        setMargin('max-w-[900px] mx-auto')
        setEmbedURL(task.embedURL)
      } else if (task.text) {
        setTaskType(LessonType.text)
        setMargin('max-w-[900px] mx-auto')
      } else if (task.quiz.length > 0) {
        setTaskType(LessonType.quiz)
        setMargin('max-w-[900px] mx-auto')
      } else {
        setTaskType(LessonType.unknown)
      }

      if (isMobile) {
        setMargin('mx-1')
      }
    }
  }, [task, isMobile])

  const COMPLETED: LearnerTrackingStatus = 'COMPLETED'

  useEffect(() => {
    if (
      (task.text || task.embedURL || task.processMapId) &&
      !task.video &&
      learnerTaskTracking?.status !== COMPLETED &&
      learnerTaskTracking?.id &&
      !currentUser.isClientAlias
    ) {
      updateLearnerTaskTracking({
        variables: {
          id: learnerTaskTracking.id,
          input: {
            progressData: [],
            status: COMPLETED,
          },
        },
      })
      setTaskStatus((vals) => {
        return [
          ...vals.filter((taskStatus) => taskStatus.taskId !== task.id),
          {
            taskId: task.id,
            status: COMPLETED,
          },
        ]
      })
    }
  }, [
    currentUser.isClientAlias,
    learnerTaskTracking,
    setTaskStatus,
    task.embedURL,
    task.id,
    task.text,
    task.video,
    updateLearnerTaskTracking,
  ])

  return (
    <div className="h-full min-w-full flex-col justify-center overflow-y-scroll bg-white p-6">
      <div className={margin}>
        <PlayerHeader title={task.name} type={taskType} />
      </div>
      <div
        className={
          taskType !== LessonType.video
            ? 'h-[calc(100%-78px)] w-full'
            : 'grid h-[calc(100%-78px)] grow'
        }
      >
        {taskType === LessonType.videoWithText ? (
          <div
            className={`${
              isMobile ? margin : 'mx-0'
            } flex flex-row items-stretch`}
          >
            {isMobile ? (
              <div className="flex-col">
                <div className="min-w-full">
                  <VideoComponent
                    taskId={task.id}
                    onUpdateLearnerTaskTracking={updateLearnerTaskTracking}
                    learnerTaskTracking={learnerTaskTracking}
                    video={videoURL}
                    onError={(errMsg) => toast.error(errMsg)}
                    setTaskStatus={setTaskStatus}
                  />
                </div>
                <div className="mt-2 max-h-[calc(100vh-35rem)] overflow-y-auto bg-gray-50 px-6 py-2">
                  <RichTextEditor defaultValue={task.text} readOnly />
                </div>
              </div>
            ) : (
              <>
                {textLayout === 'fullWidth' ? (
                  <>
                    <div className="w-full pl-5 pr-10">
                      <VideoComponent
                        taskId={task.id}
                        onUpdateLearnerTaskTracking={updateLearnerTaskTracking}
                        learnerTaskTracking={learnerTaskTracking}
                        video={videoURL}
                        onError={(errMsg) => toast.error(errMsg)}
                        setTaskStatus={setTaskStatus}
                      />
                      <div className="mx-1 mt-6 max-h-[calc(100vh-20rem)] w-full overflow-y-auto bg-gray-50 p-2">
                        <RichTextEditor defaultValue={task.text} readOnly />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-[calc(90%-15rem)] min-w-[calc(50%-15rem)]">
                      <VideoComponent
                        taskId={task.id}
                        onUpdateLearnerTaskTracking={updateLearnerTaskTracking}
                        learnerTaskTracking={learnerTaskTracking}
                        video={videoURL}
                        onError={(errMsg) => toast.error(errMsg)}
                        setTaskStatus={setTaskStatus}
                      />
                    </div>
                    <div className="mx-1 ml-6 max-h-[calc(100vh-20rem)] w-1/3 overflow-y-auto bg-gray-50 p-2">
                      <RichTextEditor defaultValue={task.text} readOnly />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        ) : taskType === LessonType.video ? (
          <div className="flex flex-col items-center justify-center pt-2">
            <div className="w-full max-w-[1024px]">
              <VideoComponent
                taskId={task.id}
                onUpdateLearnerTaskTracking={updateLearnerTaskTracking}
                learnerTaskTracking={learnerTaskTracking}
                video={videoURL}
                onError={(errMsg) => toast.error(errMsg)}
                setTaskStatus={setTaskStatus}
              />
            </div>
          </div>
        ) : taskType === LessonType.processMap ? (
          <>
            <div
              className={`e-rte-content max-h-20 overflow-y-auto ${
                isMobile ? 'mx-4' : margin
              }`}
            >
              <RichTextEditor defaultValue={task.text} readOnly />
            </div>
            <ProcessMapViewerCell id={task.processMapId} isPlayerView />
          </>
        ) : taskType === LessonType.text ? (
          <div
            className={`e-rte-content mt-6 max-h-[calc(100vh-20rem)] overflow-y-auto ${
              isMobile ? 'mx-4' : margin
            }`}
          >
            <RichTextEditor defaultValue={task.text} readOnly />
          </div>
        ) : taskType === LessonType.embedWithText ? (
          <div
            className={`${
              isMobile ? margin : 'mx-0'
            } flex flex-row items-stretch`}
          >
            {isMobile ? (
              <div className="flex-col">
                <div className="min-w-full">
                  <EmbedComponent embedURL={embedURL} isPreview={isPreview} />
                </div>
                <div className="mt-2 max-h-[calc(100vh-35rem)] overflow-y-auto bg-gray-50 px-6 py-2">
                  <RichTextEditor defaultValue={task.text} readOnly />
                </div>
              </div>
            ) : (
              <>
                {textLayout === 'fullWidth' ? (
                  <>
                    <div className="w-full pl-5 pr-10">
                      <EmbedComponent
                        embedURL={embedURL}
                        isPreview={isPreview}
                      />
                      <div className="mx-1 mt-6 max-h-[calc(100vh-20rem)] w-full overflow-y-auto bg-gray-50 p-2">
                        <RichTextEditor defaultValue={task.text} readOnly />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full min-w-[calc(50%-15rem)]">
                      <EmbedComponent
                        embedURL={embedURL}
                        isPreview={isPreview}
                      />
                    </div>
                    <div className="mx-1 ml-6 max-h-[calc(100vh-20rem)] w-1/3 overflow-y-auto bg-gray-50 p-2">
                      <RichTextEditor defaultValue={task.text} readOnly />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        ) : taskType === LessonType.embed ? (
          <div className="flex flex-col items-center justify-center pt-2">
            <div className="w-full max-w-[1024px]">
              <EmbedComponent embedURL={embedURL} isPreview={isPreview} />
            </div>
          </div>
        ) : taskType === LessonType.quiz ? (
          <div className={margin}>
            <QuizPlayer
              task={task}
              learnerTaskTracking={learnerTaskTracking}
              updateLearnerTaskTracking={updateLearnerTaskTracking}
              setTaskStatus={setTaskStatus}
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center pt-2">
            <div className="w-full max-w-[1024px]" />
          </div>
        )}
      </div>
    </div>
  )
}

export default CoursePlayerView
