import { Dispatch, FC, SetStateAction } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { AccordionSummary, Typography } from '@mui/material'

import { TaskType } from '../../types'

interface QuestionSummaryProps {
  toggleExpand: Dispatch<SetStateAction<boolean>>
  isMarking: boolean
  markQuestionIsCorrect: () => boolean
  isExpandable: boolean
  expand: boolean
  question: TaskType['quiz'][0]
}
const QuestionSummary: FC<QuestionSummaryProps> = ({
  toggleExpand,
  isMarking,
  markQuestionIsCorrect,
  isExpandable,
  expand,
  question,
}) => {
  return (
    <AccordionSummary
      expandIcon={
        <ChevronDownIcon
          className="w-8 h-8 cursor-pointer"
          onClick={() => toggleExpand(!expand)}
        />
      }
      className={`bg-white min-h-12 p-2 cursor-default rounded-t
          ${
            isMarking
              ? markQuestionIsCorrect()
                ? ' border border-green-600 bg-green-50'
                : ' border border-red-500 bg-red-50'
              : ''
          }`}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
        '& .MuiAccordionSummary-content': {
          margin: 0,
          padding: 0,
        },
        '&.Mui-expanded': {
          minHeight: 0,
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: '0',
        },
        '&.Mui-focusVisible': {
          backgroundColor: 'white',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          display: !isExpandable ? 'none' : 'block', // disable if on summary page/editing
        },
      }}
    >
      <div className="flex flex-1 justify-start ml-px">
        <div
          className={`flex flex-col justify-center w-8 h-8 mx-0 rounded ${
            isMarking
              ? markQuestionIsCorrect()
                ? 'bg-green-600'
                : 'bg-red-600'
              : 'bg-gray-300'
          }`}
        >
          <Typography
            className={`flex justify-center w-8 ${isMarking && 'text-white'}`}
          >
            Q
          </Typography>
        </div>
        <div className="flex min-h-8 mx-1">
          <span className="min-h-6 my-auto">{question.question}</span>
        </div>
      </div>
    </AccordionSummary>
  )
}

export default QuestionSummary
