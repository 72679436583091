import { useEffect, useRef, useState } from 'react'

import { oembed, validate } from '@loomhq/loom-embed'
import { Player } from 'player.js'

type Props = {
  video: string
  onTimeUpdate?: (currentTime: number, endTime: number) => void
  startTime?: number
  autoplay?: boolean
  onError: (error: string) => void
}

const LoomPlayer = (props: Props) => {
  const {
    video = '',
    onTimeUpdate,
    startTime = 0,
    autoplay = false,
    onError,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const [videoHTML, setVideoHTML] = useState('')
  const [currentTime, setCurrentTime] = useState(0.0)
  const [endTime, setEndTime] = useState(0.0)

  useEffect(() => {
    if (!videoHTML || !ref.current) {
      return
    }

    let progressInterval: NodeJS.Timeout = null

    const player = Player(ref?.current?.querySelector('iframe'))
    // sets the time the video should start
    player.on('ready', () => {
      if (autoplay) {
        if (player.supports('method', 'mute')) {
          player.mute()
        }
        player.play()
      }
      player.setCurrentTime(startTime)
      player.getDuration((time: number) => setEndTime(time))
    })
    // Updates the current time on an interval
    player.on('play', () => {
      clearInterval(progressInterval)
      progressInterval = setInterval(() => {
        player.getCurrentTime((currentTime: number) =>
          setCurrentTime(currentTime),
        )
      }, 15000)
      player.getDuration((time: number) => setEndTime(time))
    })
    player.on('seek', () => {
      clearInterval(progressInterval)
    })
    // Updates the current time when video ends
    player.on('ended', () => {
      clearInterval(progressInterval)
      player.getCurrentTime((time: number) => setCurrentTime(time))
      player.getDuration((time: number) => setEndTime(time))
    })
    // Updates the current time on a user pause event
    player.on('pause', () => {
      clearInterval(progressInterval)
      player.getCurrentTime((currentTime: number) =>
        setCurrentTime(currentTime),
      )
    })

    return () => {
      // HACK: This function gets called in the player.js lib after the player is destroyed
      // So that we don't get errors, we override to a noop function
      player.send = function () {}
      player?.off('ready')
      player?.off('play')
      player?.off('pause')
      player?.off('ended')
      player?.off('seek')
    }
  }, [videoHTML])

  useEffect(() => {
    onTimeUpdate?.(currentTime, endTime)
  }, [currentTime])

  useEffect(() => {
    ;(async () => {
      if (validate.isLoomUrl(video)) {
        const { html } = await oembed(video)
        setVideoHTML(html)
      } else {
        onError('Invalid loom link:' + video)
      }
    })()
  }, [video, onError])

  return videoHTML ? (
    <div
      ref={ref}
      id="player"
      dangerouslySetInnerHTML={{ __html: videoHTML }}
    />
  ) : null
}

export default LoomPlayer
