import React, { useEffect, useRef, useState } from 'react'

import { Player } from 'player.js'

interface IFramePlayerProps {
  video: string
  onTimeUpdate: (currentTime: number, endTime: number) => void
  startTime?: any
}

const IFramePlayer: React.FC<IFramePlayerProps> = ({
  video,
  onTimeUpdate,
  startTime = 0,
}) => {
  const iframe = useRef<HTMLIFrameElement>(null)
  const [currentTime, setCurrentTime] = useState(0.0)
  const [endTime, setEndTime] = useState(0.0)

  let progressInterval: NodeJS.Timeout

  useEffect(() => {
    let player: Player | undefined

    if (video && iframe.current) {
      player = new Player(iframe.current)

      player.on('ready', () => {
        player.setCurrentTime(startTime)
        player.getDuration((time) => {
          if (!time) return
          setEndTime(time)
        })
      })

      player.on('play', () => {
        clearInterval(progressInterval)
        progressInterval = setInterval(() => {
          player.getCurrentTime((currentTime) => setCurrentTime(currentTime))
        }, 15000)
        player.getDuration((time) => {
          if (!time) return
          setEndTime(time)
        })
      })

      player.on('seek', () => {
        clearInterval(progressInterval)
      })

      // Updates the current time when video ends
      player.on('ended', () => {
        clearInterval(progressInterval)
        player.getCurrentTime((time) => setCurrentTime(time))
        player.getDuration((time) => setEndTime(time))
      })
      // Updates the current time on a user pause event
      player.on('pause', () => {
        clearInterval(progressInterval)
        player.getCurrentTime((currentTime) => setCurrentTime(currentTime))
        player.getDuration((time) => setEndTime(time))
      })
    }

    return () => {
      player?.off()
    }
  }, [video])

  useEffect(() => {
    onTimeUpdate?.(currentTime, endTime)
  }, [currentTime])

  return (
    <div className="player-wrapper">
      <iframe
        ref={iframe}
        title="StoryXpress Video"
        className="react-player"
        src={video}
        frameBorder="0"
        allowFullScreen
        width="100%"
        height="100%"
      />
    </div>
  )
}

export default IFramePlayer
